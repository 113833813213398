.hero-insular {
    width: 47%; }

.insular-title {
    font-size: 3.5em;
    margin-bottom: 0;
    margin-top: 10px; }

.esfera-1 {
    width: 50%;
    float: right;
    margin-bottom: -14%;
    z-index: 2;
    position: relative;
    mix-blend-mode: multiply; }

.esfera-2 {
    width: 48%;
    margin-top: -7%;
    margin-left: 5%;
    mix-blend-mode: multiply; }

.esfera-3 {
    width: 20%;
    float: right;
    z-index: 2;
    position: {}
    mix-blend-mode: multiply;
    height: 100%;
    margin-left: -5%; }

.z-10 {
    z-index: 10 !important; }

.z-0 {
    z-index: 0 !important; }

a {
    text-decoration: none;
    color: inherit; }

a:hover {
    color: inherit; }


.fuso-insular-banner:hover {
    background-color: white; }

.title2-size {
    font-size: 7vw; }


.title3-size {
    font-size: 3vw; }

.underline {
    text-decoration: underline; }

.online-btn {
    border: 1px solid $red;
    border-radius: 50%;
    padding: 15px 20px 7px 20px;
    font-size: 2em; }


.online-btn:hover {
    color: $red; }


@media only screen and (min-width: 600px) {
    .desktop {
        display: block; }

    .mobile {
        display: none; }

    .w-70 {
        width: 70%; }

    #footer {
        margin-bottom: 10vh !important; } }

@media only screen and (max-width: 600px) {
    .desktop {
        display: none !important; }

    .mobile {
        display: block !important; }

    .hero-insular {
        width: 100%; }

    .insular-title {
        font-size: 1.5em; }

    .esfera-insular-mobile {
        width: 55%;
        margin-bottom: -5px; }

    .font-larger {
        font-size: 1.5em; }

    .mobile-45-logo {
        height: 44vh; }

    .esfera-1 {
        width: 100%;
        float: left; }

    .fuso-index-dates {
        margin-top: -18%; }

    .fuso-banner {
        height: auto; }

    .fuso-insular-banner {
        padding-bottom: 15vh !important; }

    .title2-size {
        font-size: 12vw; } }

