.navbar-container {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    transition: 0.2s; }

.nav-active {
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $red; }

.nav-shadow {
    transition: 0.2s;
    background-color: white; }


/* HAMBURGER NAV */

#close-icon {
    width: 40px;
    height: 30px;
    position: relative;
    margin: 10px;
    margin-left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-bottom: 1.8em;
    z-index: 10; }


#close-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: black;
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }

#close-icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }


#close-icon span:nth-child(2) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }


#close-icon span:nth-child(3) {
    top: 26px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }


#close-icon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px; }


#close-icon.open span:nth-child(2) {
    width: 0%;
    opacity: 0; }


#close-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 25px;
    left: 8px; }

.mobile-nav {
    display: none; }

.site-title {
    width: 24%;
    transition: 0.85s; }

.nav-item {
    font-size: 1.8em; }

.nav-item-first {
    font-size: 1.8em; }

.switch-button {
    /// background-color: $red
    border: 2px solid;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 2vh; }

.switch-button:hover {
    background-color: transparent !important;
    border: 2px solid;
    color: black !important; }

.switch-btn-insular {
    border: 2px solid black; }

.switch-btn-insular:hover {
    background-color: transparent !important;
    border: 2px solid black;
    color: black; }

.insular-section {
    width: 50%;
    margin-left: 2em; }

.insular-full-width {
    width: 100%;
    margin-left: 0; }


@media only screen and (min-width: 1024px) {
    .nav-flex {
        display: flex; }

    .lg-full-width {
        width: 100%; }

    .language {
        position: absolute;
        top: 10px;
        right: 20px; }

    .nav-item {
        font-size: 1.3vw;
        padding: 1px 10px 3px 10px; }

    .nav-item-first {
        font-size: 1.3vw;
        padding: 1px 10px 3px 0px; }

    .nav-active {
        border-radius: 50%;
        border: 2px solid $red;
        padding: 1px 10px 3px 10px;
        text-decoration: none; }

    .smallLogo {
        width: 24%;
        transition: 0.85s; }

    .navbar-container {
        min-height: 20vh; } }

@media only screen and (max-width: 1024px) {
    .site-title {
        font-size: 6.7vw;
        width: 100% !important; }

    .desktop-nav {
        display: none !important; }

    .mobile-nav {
        display: block; }

    .pages-nav {
        position: absolute;
        top: 0;
        right: -90vw;
        background-color: white;
        border-left: 2px solid black;
        padding: 1em 2em 2em 1em;
        font-size: 1.5em;
        line-height: 1.8em;
        transition: 0.3s;
        height: 100vh; }

    .pages-nav-open {
        right: 0;
        transition: 0.3s;
        display: block; }

    .fuso-insular-nav-mobile {
        flex-direction: column; }

    .insular-section {
        width: 100%;
        margin-left: 0; }

    .navbar-btn-mobile {
        background-color: $red !important;
        color: black !important;
        border: 2px solid $red; } }
