$red: #fff22d;

@font-face {
    font-family: "Roxie Rossa";
    src: url("../fonts/Roxie Rossa 400.otf");
    font-weight: normal; }

@font-face {
    font-family: "Sequel Sans Roman Body";
    src: url("../fonts/Sequel Sans Roman Body.otf");
    font-weight: normal; }

.font-rossa {
    font-family: 'Roxie Rossa'; }

.font-sequel {
    font-family: "Sequel Sans Roman Body"; }

body {
    font-family: "Sequel Sans Roman Body";
    overflow-x: hidden;
    color: black; }

.bg-white {
    background-color: white; }

.bg-red {
    background-color: $red; }

.bg-black {
    background-color: black; }

.w-40 {
    width: 40%; }

.small-text {
    font-size: 0.8em; }

.h5, h5 {
    line-height: 1.4em; }

p {
    line-height: 1.6em; }

.multiply {
    mix-blend-mode: multiply; }

.float-right {
    float: right; }

.border-dark {
    border-color: black !important; }

.border-red {
    border-color: $red !important; }

.object-fit-cover {
    object-fit: cover; }

.saturate {
    filter: saturate(0); }

.max-height-60 {
    max-height: 60vh; }

.text-red {
    color: $red; }

.text-white {
    color: white; }

.text-smaller {
    font-size: 0.8em; }

.w-10 {
    width: 10%; }

.w-5 {
    width: 5%; }
