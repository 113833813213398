.partners-logo {
    width: 30%;
    mix-blend-mode: multiply; }

.opencall-left {
    position: -webkit-sticky;
    position: sticky;
    top: 23vh; }

.sticky-btn {
    position: -webkit-sticky;
    position: sticky;
    top: 26vh; }

.sticky-btn-opencall {
    position: -webkit-sticky;
    position: sticky;
    top: 23vh; }

.esfera-about-right {
    width: 40%;
    margin-bottom: 1.5em; }

.about-left a {
    text-decoration: underline; }


@media only screen and (max-width: 1024px) {
    .open-call-img {
        width: 100%; }

    .sticky-btn-opencall, .sticky-btn {
        width: -webkit-fit-content;
        padding: 0 15px; }

    .lab-img {
        border: 2px solid black; } }

@media only screen and (min-width: 1024px) {
    .open-call-img {
        height: 80vh; }

    .w-xl-50 {
        width: 50%; }

    .about-left {
        width: 57%;
        margin-right: 3rem; }

    .about-right {
        padding-right: 18vw; }


    .esfera-about-right {
        width: 20%;
        float: right;
        z-index: 2;
        position: absolute;
        right: 2vw;
        top: 40%; }

    .overflow-scroll {
        overflow: scroll !important;
        height: 80vh; }

    .partners-logo {
        width: 10%; } }
