.partners-logo-footer {
    height: 44px;
    margin-bottom: 10px;
    mix-blend-mode: multiply; }

.smaller-text {
    font-size: 0.9em; }

.financing-logo {
    height: 88px; }

@media only screen and (min-width: 1024px) {
    .logos-footer {
        margin-left: 18vw; }
    .partners-logo-footer {
        margin-bottom: 10px;
        height: 40px; } }

@media only screen and (max-width: 1024px) {
    .logos-footer {
        margin-top: 2em; } }


@media only screen and (max-width: 400px) {
    .partners-logo-footer {
        height: 33px;
        object-fit: contain; } }
